//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  /* margin-top: $line-height-computed; */
  list-style: none;
/*   background-color: $breadcrumb-bg; */
  border-radius: $border-radius-base;
  margin-left: -15px;
  position: relative;
  .breadcrumb-links{
    position: relative;
    > li {
      display: inline-block;
      a{
        color: $text-color;
        &:hover{
          color: $link-hover-color;
        }
      }
      + li:before {
        content: "\f105";
        font-family: FontAwesome;
        font-size: 14px;
        color: $breadcrumb-color;
        position: absolute;
      }
    }

    > .active {
      color: $breadcrumb-active-color;
    }
  }
}
